import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import { dashboard_yellow_thumb_like } from "../../../assets/images";

const InsuranceHolderRentalConfirm = () => {
  return (
    <div className="col-md-12">
      <div className="row">
        <div className="col-md-9 text-white text-start pt-5">
          <div className="d-flex">
            <div className="me-2">
              <img
                alt="dashboard_yellow_thumb_like"
                src={dashboard_yellow_thumb_like}
              />
            </div>
            <div>
              <div className="heading-Insurance-holder-rental-confirm">
                You have successfully rented the car for 2 days and it ready for
                collection now
              </div>
              <div className="heading-bottom-insurance-holder-rental-confirm pb-3">
                Kindly collect the car from our location
              </div>
              <Link
                to="/Customer/MyAccount"
                className="link-style-insurance-holder-rental-confirm pt-3"
              >
                Back to My Account Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceHolderRentalConfirm;
