import { useRef, useState } from "react";

import { useForm } from "react-hook-form";
import DateTimeComponent from "../../dateAndTime";
import {
  arrow_rounded,
  dashboard_car_yellow_icon,
  dashboard_special_deal,
  dashboard_calendar_yellow_icon,
  dashboard_customer_arrow,
  dashboard_location_yellow_icon,
  group_icon,
  carousel_image_one,
  mileage,
  noun_no_image,
  petrol_pump,
  vector,
} from "../../../assets/images";
import { StarFill } from "react-bootstrap-icons";
import { Carousel } from "react-bootstrap";
import CustomerCustomizeYourSearch from "../CustomerCustomizeYourSearch";
import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { bookingData, ProductSearch, searchResult } from "../../../types";
import { useNavigate } from "react-router-dom";
import MerchantDashboardReviews from "../../MerchantDashboard/MerchantDashboardReviews";
import FloatingInputLocation from "../../FloatingInputLocation";

import TimePicker from "../../TimePicker/components/TimePicker";
import moment from "moment";

interface Location {
  latitude: number | null;
  longitude: number | null;
}

const CustomerDashBoardMyAccount = () => {
  const [customizeResult, setCustomizeResult] = useState<any>(null);
  const [pickupLocation, setPickupLocation] = useState<Location>({
    latitude: 0,
    longitude: 0,
  });
  const navigate = useNavigate();
  const [showCreateProduct, setShowCreateProduct] = useState(false);
  const accessToken = localStorage.getItem("access_token");
  const [returnLocation, setReturnLocation] = useState<Location>({
    latitude: 0,
    longitude: 0,
  });
  const [pickupDate, setPickupDate] = useState<string>("");
  const [dropOffDate, setDropOffDate] = useState<string>("");
  const [searchCars, SetSearchCars] = useState<any>([]);
  const [selectedTime, setSelectedTime] = useState("");
  const [searchResultHeading, setSearchResultHeading] = useState(false);
  const [selectedDropTime, setSelectedDropTime] = useState("");
  const [searchValues, setSearchValues] = useState<any>({
    pickup: "",
    return: "",
  });
  const { control } = useForm({
    mode: "onChange",
  });
  let searchResultData: searchResult[] = [];

  const handleDropOffTimeChange = (newTime: any) => {
    console.log("Selected time:", newTime);
    setSelectedDropTime(newTime);
  };

  const handleTimeChange = (time: any) => {
    setSelectedTime(time);
  };

  const handleCustomizeResult = (customResult: any) => {
    setCustomizeResult(customResult);
  };

  const handleCustomization = () => {
    setShowCreateProduct(true);
  };

  const [pickupSuggestions, setPickupSuggestions] = useState<
    { name: string; latitude: number; longitude: number }[]
  >([]);
  const [returnSuggestions, setReturnSuggestions] = useState<
    { name: string; latitude: number; longitude: number }[]
  >([]);

  const fetchCoordinates = async (searchQuery: string) => {
    try {
      const response = await axiosInstance.get(
        `https://nominatim-carhire-dev.livelocal.delivery/search.php?q=${searchQuery}&format=geocodejson`
      );
      if (response.data && response.data.features.length > 0) {
        const suggestions = response.data.features.map((feature: any) => ({
          name: feature.properties.geocoding.label,
          latitude: feature.geometry.coordinates[1], // Extract latitude from coordinates
          longitude: feature.geometry.coordinates[0], // Extract longitude from coordinates
        }));
        return suggestions;
      }
      return [];
    } catch (error) {
      console.error("Error fetching coordinates:", error);
      return [];
    }
  };

  const handlePickupLocationChange = async (value: string) => {
    setSearchValues((prevValues: any) => ({ ...prevValues, pickup: value }));
    try {
      const suggestions = await fetchCoordinates(value);
      if (suggestions.length > 0) {
        const { latitude, longitude } = suggestions[0];
        setPickupSuggestions(suggestions);
        setPickupLocation({ latitude, longitude });
      } else {
        setPickupSuggestions([]);
        setPickupLocation({ latitude: null, longitude: null });
      }
    } catch (error) {
      console.error("Error fetching pickup location suggestions:", error);
    }
  };

  const handleReturnLocationChange = async (value: string) => {
    setSearchValues((prevValues: any) => ({ ...prevValues, return: value }));
    try {
      const suggestions = await fetchCoordinates(value);
      if (suggestions.length > 0) {
        const { latitude, longitude } = suggestions[0];
        setReturnSuggestions(suggestions);
        setReturnLocation({ latitude, longitude });
      } else {
        setReturnSuggestions([]);
        setReturnLocation({ latitude: null, longitude: null });
      }
    } catch (error) {
      console.error("Error fetching return location suggestions:", error);
    }
  };

  // const handlePickupLocationSelection = (selectedLocation: {
  //   name?: string;
  //   latitude: any;
  //   longitude: any;
  // }) => {
  //   setPickupLocation({
  //     latitude: selectedLocation.latitude,
  //     longitude: selectedLocation.longitude,
  //   });

  //   // Clear suggestions after selection
  //   setPickupSuggestions([]);
  // };
  const handlePickupLocationSelection = (selectedLocation: {
    name?: string;
    latitude: any;
    longitude: any;
  }) => {
    setSearchValues((prevValues: any) => ({
      ...prevValues,
      pickup: selectedLocation.name,
    }));
    setPickupSuggestions([]);
  };

  const handleReturnLocationSelection = (selectedLocation: {
    name?: string;
    latitude: any;
    longitude: any;
  }) => {
    setSearchValues((prevValues: any) => ({
      ...prevValues,
      return: selectedLocation.name,
    }));
    setReturnLocation({
      latitude: selectedLocation.latitude,
      longitude: selectedLocation.longitude,
    });
    setReturnSuggestions([]);
  };

  const handlePickupDateChange = (date: string) => {
    if (new Date(date) > new Date(dropOffDate)) {
      setDropOffDate(date);
    }
    setPickupDate(date);
  };

  const handleDropOffDateChange = (date: string) => {
    if (new Date(date) < new Date(pickupDate)) {
      setDropOffDate(pickupDate);
    } else {
      setDropOffDate(date);
    }
  };

  const handleSubmit = () => {
    let seatCapacity = null;
    let carType = null;
    let amountRange = 0;

    if (customizeResult) {
      const { seat_capacity, car_type, amount_range } = customizeResult;
      seatCapacity = seat_capacity;
      carType = car_type;
      amountRange = amount_range;
    }
    const convertToString = (value: number | null) =>
      value !== null ? value.toString() : "";

    const userData: ProductSearch = {
      index: 1,
      count: 10,
      sort_order: "ASC",
      pickup_location: {
        latitude: convertToString(pickupLocation.latitude),
        longitude: convertToString(pickupLocation.longitude),
      },
      return_location: {
        latitude: convertToString(returnLocation.latitude),
        longitude: convertToString(returnLocation.longitude),
      },
      // pickup_date: pickupDate,
      // dropOff_date: dropOffDate,
      pickup_date: moment(pickupDate).format("YYYY-MM-DDTkk:mm:ss.sss") + "Z",
      dropOff_date: moment(dropOffDate).format("YYYY-MM-DDTkk:mm:ss.sss") + "Z",
      pickup_time: selectedTime,
      dropOff_time: selectedDropTime,
      ...(seatCapacity && { seat_capacity: seatCapacity }),
      ...(carType && { car_type: carType }),
      ...(amountRange !== 0 && { amount_range: amountRange.toFixed(1) }),
    };

    handleSearch.mutate(userData);

    const searchResultSection = document.getElementById("searchResultSection");
    if (searchResultSection) {
      searchResultSection.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleSearch = useMutation({
    mutationFn: (userData: ProductSearch) =>
      axiosInstance.post(API_URL.PRODUCT_SEARCH, userData),
    onSuccess: (successResponse) => {
      searchResultData = successResponse.data;
      SetSearchCars(searchResultData);
      setSearchResultHeading(true);
      searchResultData?.map((item: any, index: number) => {
        console.log(`Product ID ${index + 1}:`, item.product_name);
      });
    },

    onError: (error) => {
      console.error("Search error:", error);
    },
  });

  const decodeToken = (token: any) => {
    if (token) {
      return JSON.parse(atob(token.split(".")[1]));
    } else {
      return null;
    }
  };

  const decodedToken = decodeToken(accessToken);
  const customerId =
    decodedToken && decodedToken["customer-id"]
      ? decodedToken["customer-id"]
      : null;

  const handleBooking = useMutation({
    mutationFn: (userData: bookingData) =>
      axiosInstance.post(
        `${API_URL.BOOKING_NOW}/${customerId}/carts`,
        userData
      ),
    onSuccess: (successResponse) => {
      console.log("handle booking success", successResponse);
    },

    onError: (error) => {
      console.log("there was an error", error);
    },
  });

  const handleBookingClick = (productId: string) => {
    const selectedItem = searchCars.find(
      (item: any) => item.product_id === productId
    );
    if (!selectedItem) {
      console.error("Selected item not found in searchCars array.");
      return;
    }
    const payload: bookingData = {
      product_id: selectedItem?.product_id,
      base_product_id: selectedItem?.product_id,
      product_name: selectedItem?.product_name,
      product_description: selectedItem?.product_description,
      merchant_id: selectedItem?.merchant_id,
      quantity: 1,
      item_id: "",
      promotion_id: "",
      price: selectedItem?.product_details_car?.price_per_day,
      toppings: [],
      buy_more_pay_less: {},
      discount_event: {},
      variant: {},
      shop_id: selectedItem?.vendor_product_inventories[0]?.vendor_id,
      product_details: selectedItem?.product_details?.description,
    };
    handleBooking.mutate(payload);

    const searchData = {
      pickupPlace: searchValues.pickup,
      returnPlace: searchValues.return,
      pickupDate,
      dropOffDate,
      selectedTime,
      selectedDropTime,
      pickupLocLatitude: pickupLocation?.latitude?.toString(),
      pickupLocLongitude: pickupLocation?.longitude?.toString(),
    };

    navigate("/Customer/BP_Non_Holder", {
      state: { selectedItem, merchantId: selectedItem.merchant_id, searchData },
    });
  };

  return (
    <div className="row justify-content-md-center my-account-component-div">
      <div className="col-md-12">
        <div className="row ">
          <div className="col-md-12 background-div-carousal">
            <Carousel
              className="customer-carousel"
              pause={false}
              controls={false}
            >
              <Carousel.Item>
                <img
                  src={carousel_image_one}
                  alt=""
                  className="img-fluid carousal-image-my-account"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={carousel_image_one}
                  alt=""
                  className="img-fluid carousal-image-my-account"
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
        <div className="row text-center pt-3 pb-3">
          <div className="col-md-12  d-flex justify-content-center me-2">
            <span className="myAccount-head-card ">
              Select the best rent a car with KIRKOS below
            </span>
            <img src={arrow_rounded} alt="" width={100} />
          </div>
        </div>
        {/* -------------------------------------- search card start ------------------------------- */}
        <div className="card custom-myAccount-card ">
          <div className="card-body">
            <div className="row">
              <div className="col-md-6 relative-div">
                <FloatingInputLocation
                  inputType={"text"}
                  className={""}
                  placeholder={"Pick Up Location"}
                  label={"Pick Up Location"}
                  inputName="pickUpLocation "
                  selectedLocationName={searchValues.pickup}
                  // onInputChange={(value: string) =>{}}
                  onInputChange={(value: string) =>
                    handlePickupLocationChange(value)
                  }
                  control={control}
                  editable={true}
                />
                {pickupSuggestions.length > 0 && (
                  <div className="absolute-div">
                    <ul className="text-start" style={{ color: "white" }}>
                      {pickupSuggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          onClick={() =>
                            handlePickupLocationSelection(suggestion)
                          }
                        >
                          {suggestion.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <div className="col-md-6 relative-div">
                <FloatingInputLocation
                  inputType={"text"}
                  className={""}
                  placeholder={"Return Location"}
                  label={"Return Location"}
                  inputName="returnLocation"
                  onInputChange={(value: string) =>
                    handleReturnLocationChange(value)
                  }
                  selectedLocationName={searchValues.return}
                  control={control}
                  editable={true}
                />
                {returnSuggestions.length > 0 && (
                  <div className="absolute-div">
                    <ul className="text-start" style={{ color: "white" }}>
                      {returnSuggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          onClick={() =>
                            handleReturnLocationSelection(suggestion)
                          }
                        >
                          {suggestion.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-xl-9">
                <div className="row">
                  <div className="col-xl-3 col-md-6 pt-2">
                    <DateTimeComponent
                      pickerType="date"
                      placeholder="Pick Up Date"
                      label="Pick up Date"
                      value={pickupDate}
                      onChange={handlePickupDateChange}
                    />
                  </div>
                  <div className="col-xl-3 col-md-6 pt-2">
                    <DateTimeComponent
                      pickerType="date"
                      placeholder="Dropoff Date"
                      label="Dropoff Date"
                      value={dropOffDate}
                      onChange={handleDropOffDateChange}
                    />
                  </div>
                  <div className="col-xl-3 col-md-6 pt-2">
                    <TimePicker
                      value={null}
                      label="Pick Up Time"
                      onChange={handleTimeChange}
                    />
                  </div>
                  <div className="col-xl-3 col-md-6 text-center pt-2">
                    <TimePicker
                      value={null}
                      label="Drop-Off Time"
                      onChange={handleDropOffTimeChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="row">
                  <div className="col-md-12 text-end pt-2">
                    <button
                      className="btn search-submit-btn "
                      onClick={handleSubmit}
                      disabled={
                        !pickupDate ||
                        !dropOffDate ||
                        !selectedTime ||
                        !selectedDropTime ||
                        !pickupLocation.latitude ||
                        !pickupLocation.longitude ||
                        !returnLocation.latitude ||
                        !returnLocation.longitude
                      }
                    >
                      SEARCH
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-end p-2 px-4">
            <button
              className="custom-search-my-account"
              type="button"
              onClick={handleCustomization}
            >
              Customize your search
            </button>
          </div>
        </div>
        {/* -------------------------------------- search card end ------------------------------- */}

        {/* -------------------------------------- search result start ------------------------------- */}
        <div id="searchResultSection" />
        <div
          className={`row home-search-min-height align-items-center ${
            searchResultHeading ? "" : "d-none"
          }`}
        >
          <div className="row text-start pt-3 pb-3">
            <div
              className={`col-md-12 myAccount-head  ${
                searchResultHeading ? "" : "d-none"
              }`}
            >
              {searchCars.length === 0 ? (
                <div className="text-center">
                  <div>
                    <img src={vector} alt="Error Logo" className="img-fluid" />
                  </div>
                  <span style={{ color: "#eeeeee" }}>
                    No Car On The Searched Location
                  </span>
                </div>
              ) : (
                <span>Your Search Result Below :</span>
              )}
            </div>
          </div>
          <div className="row">
            {/* ---------------------  search mapping start ----------------------- */}

            {searchCars &&
              searchCars?.map((item: any, index: number) => (
                <div key={index} className="col-md-6 pt-4 animate-by-scroll">
                  <div className="row normal-height-search-list">
                    <div className="col-md-10 mx-auto">
                      <div className="card card-custom-my-account">
                        {/* <img
                        className="img-fluid search-result-image"
                        src={item?.product_details?.images[0]}
                        alt=""
                      /> */}
                        {item?.product_details?.images?.length > 0 ? (
                          <img
                            className="img-fluid search-result-image"
                            src={item.product_details.images[0]}
                            alt=""
                          />
                        ) : (
                          <img
                            src={noun_no_image}
                            alt=""
                            className="img-fluid search-result-image"
                          />
                        )}
                        <div className="card-body">
                          <div className="row text-start">
                            <div className="col-6">
                              <div className="car-cat-search ">
                                {item.product_name}
                              </div>
                              <div className="car-name-search">
                                {item.product_name}
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="text-center merchant-name-div mx-2">
                                <span>
                                  {" "}
                                  {item?.site_logo ? (
                                    <img
                                      className="img-fluid logo-merchant"
                                      src={item?.site_logo}
                                      alt=""
                                    />
                                  ) : (
                                    <span>
                                      {String(item?.shop_name)?.toUpperCase()}
                                    </span>
                                  )}
                                </span>
                              </div>
                              {/* <div className="text-center merchant-name-div mx-2">
                                <span> {item?.shop_name}</span>
                              </div> */}
                              <div className="text-center pt-2">
                                <StarFill color={"#F0B64F"} size={12} />
                                <span className="ps-2">
                                  <StarFill color={"#F0B64F"} size={12} />
                                </span>
                                <span className="ps-2">
                                  <StarFill color={"#F0B64F"} size={12} />
                                </span>
                                <span className="ps-2">
                                  <StarFill color={"#F0B64F"} size={12} />
                                </span>
                                <span className="ps-2">
                                  <StarFill color={"#F0B64F"} size={12} />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row pt-2 search-features">
                            <div className="col-6">
                              <div className="col-md-12 text-start  pb-2 dashboard-poppins-font-style  car-name-search-bold">
                                Features
                              </div>
                              <div className="d-flex">
                                <div className="text-start dashboard-poppins-font-style me-2">
                                  <img
                                    src={group_icon}
                                    alt=""
                                    className="img-fluid img-width-icon"
                                  />
                                  &nbsp;
                                  {item.product_details_car.number_of_seats}
                                </div>
                                <div className="text-start dashboard-poppins-font-style">
                                  <img
                                    src={petrol_pump}
                                    alt=""
                                    className="img-fluid img-width-icon"
                                  />
                                  &nbsp;{item.product_details_car.fuel_type}
                                </div>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="search-car-special-deal text-center">
                                <img
                                  src={dashboard_special_deal}
                                  alt=""
                                  width={13}
                                  className=""
                                />
                                <span className="dashboard-poppins-font-style">
                                  {" "}
                                  Special Deal{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row search-features">
                            <div className="col-md-12 text-start pt-1 ">
                              <img
                                src={mileage}
                                alt="file_browse_icon"
                                className="img-fluid img-width-icon"
                                style={{ alignItems: "left" }}
                              />
                              <span className="px-1 dashboard-poppins-font-style">
                                Unlimited Mileage
                              </span>
                            </div>
                          </div>
                          <div className="row text-start pt-4">
                            <div className="col-md-4">
                              <div className="search-car-rate dashboard-poppins-font-style">
                                AED {item.product_details_car.price_per_day}
                              </div>
                              <div className=" car-name-search-bold dashboard-poppins-font-style">
                                Per Day
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="search-car-rate dashboard-poppins-font-style">
                                AED {item.product_details_car.price_per_week}
                              </div>
                              <div className="car-name-search dashboard-poppins-font-style">
                                Per Week
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="search-car-rate dashboard-poppins-font-style">
                                AED {item.product_details_car.price_per_month}
                              </div>
                              <div className="car-name-search dashboard-poppins-font-style">
                                Per Month
                              </div>
                            </div>
                          </div>
                          <div className="row pt-4">
                            <div className="col-md-12 text-end">
                              <button
                                className="book-now-button-style "
                                onClick={() =>
                                  handleBookingClick(item.product_id)
                                }
                              >
                                BOOK NOW
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            {/* ---------------------  search mapping end ----------------------- */}
          </div>
        </div>
        {/* -------------------------------------- search result end ------------------------------- */}
        {/* ---------------------------- working start --------------------------------- */}
        <div className="row pt-3">
          <div className="col-md-12">
            <h2 className="heading-works">
              How It <span className="heading-works-sub">Works</span>
            </h2>
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-md-12">
            <div className="card card-for-working">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-2">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="col-md-8 mx-auto icon-div-bg px-3 pt-3 pb-3">
                          <img
                            src={dashboard_location_yellow_icon}
                            height={50}
                            alt=""
                            className="img-fluid icon-div-bg-size"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 card-for-working-head pt-2">
                        Choose Location
                      </div>
                      <div className="col-md-12 card-for-working-div">
                        Choose your location and find the best car
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 img-arrow-custom-div ">
                    <img
                      alt="dashboard_customer_arrow"
                      src={dashboard_customer_arrow}
                      className="img-arrow-custom img-fluid"
                    />
                  </div>
                  <div className="col-md-2">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="col-md-8 mx-auto icon-div-bg px-3 pt-3 pb-3">
                          <img
                            src={dashboard_calendar_yellow_icon}
                            height={50}
                            alt=""
                            className="img-fluid icon-div-bg-size"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 card-for-working-head pt-2">
                        Pick Up Date
                      </div>
                      <div className="col-md-12 card-for-working-div">
                        Select your pickup date & time to book your car
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 img-arrow-custom-div">
                    <img
                      alt="dashboard_customer_arrow_2"
                      src={dashboard_customer_arrow}
                      className="img-arrow-custom img-fluid"
                    />
                  </div>
                  <div className="col-md-2">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="col-md-8 mx-auto icon-div-bg px-3 pt-3 pb-3">
                          <img
                            src={dashboard_car_yellow_icon}
                            height={50}
                            alt=""
                            className="img-fluid icon-div-bg-size"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 card-for-working-head pt-2">
                        Book Your Car
                      </div>
                      <div className="col-md-12 card-for-working-div">
                        Book your car and pickup from the location or we will
                        deliver you
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------------------- working end --------------------------------- */}
        {/* ----------------- Reviews ---------------------------------------- */}
        <MerchantDashboardReviews />
        {/* ------------------------- review -----------------------------  */}
      </div>

      <CustomerCustomizeYourSearch
        isOpen={showCreateProduct} // Pass the state as isOpen
        onClose={() => setShowCreateProduct(false)} // Close action handler
        onCustomizeResult={handleCustomizeResult}
      />
    </div>
  );
};

export default CustomerDashBoardMyAccount;
