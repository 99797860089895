import React, { useEffect, useState } from "react";
import { Gear } from "react-bootstrap-icons";
import "./MerchantDashboardSidebar.css";

import {
  kirkos_home,
  dashboard_icon,
  dashboard_car,
  dashboard_list_my_car,
  dashboard_notification,
  dashboard_car_status,
  dashboard_trip_status,
  dashboard_settings,
  dashboard_monthly_income,
  dashboard_transactions_status,
  dashboard_customer_invoices,
  dashboard_upgrade,
  dashboard_logout,
  sidebar_collapse_icon,
  light_mode_icon,
  dark_mode_icon,
  dashboard_logo_kirkos,
  dashboard_logo_kirkos_collapse,
  dashboard_deposit,
  kirkos_logo_white_yellow,
  dashboard_invoice_approve,
  deliveredIcon,
  recollectedIcon,
} from "../../../assets/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { colors } from "react-select/dist/declarations/src/theme";
import axiosInstance, { log_out } from "../../../services/axios.instance";
import { clearBookingValues } from "../../../Redux/BookingData/bookingDataSlice";
import { useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { API_URL } from "../../../services/apiConfig";
import { NotificationCount } from "../../../types";

interface MerchantDashboardSidebarProps {
  currentPage: string;
  setCurrentPage: (page: string) => void;
  // notifications: string[];
  // newNotificationsCount: number;
  // setNewNotificationsCount: (count: number) => void;
}

const MerchantDashboardSidebar: React.FC<MerchantDashboardSidebarProps> = ({
  setCurrentPage,

  // newNotificationsCount,
  // setNewNotificationsCount,
}) => {
  const [selectedNav, setSelectedNav] = useState("dashboard");
  const [selectedNavToggle, setSelectedNavToggle] = useState(false);
  // const [showNotification, setShowNotification] = useState(false);
  // const [notifications, setNotifications] = useState<string[]>([]);
  const [newNotificationsCount, setNewNotificationsCount] = useState(0);
  //----------------------------------------------------

  const {
    data: countData,

    isError,
    error,
  } = useQuery<NotificationCount[], Error>({
    queryKey: ["notificationCounts"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.NOTIFICATION_COUNT);
      console.log(response, "Countresponse");
      return response.data;
    },
    refetchInterval: 3500,
  });

  // useEffect(() => {
  //   console.log("countData", countData);
  //   if (countData) {
  //     const count = countData?.filter(
  //       (notification: { isViewed: any }) => !notification.isViewed
  //     ).length;
  //     setNewNotificationsCount(count);
  //   }
  // }, [countData]);
  useEffect(() => {
    console.log(countData, "CountData");
    if (Array.isArray(countData)) {
      const count = countData.filter(
        (notification: NotificationCount) => !notification.isViewed
      ).length;
      setNewNotificationsCount(count);
    } else {
      console.error("countData is not an array:", countData);
    }
  }, [countData]);

  //--------------------------------------------------------
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      setSelectedNavToggle(window.innerWidth < 600);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const path_location = useLocation();

  useEffect(() => {
    if (path_location.pathname === "/Merchant/ApprovedOrders") {
      handleSetActiveNavigation("Acceptedorder");
    }
  }, [path_location.pathname]);

  const handleSetNavToggle = () => {
    setSelectedNavToggle(!selectedNavToggle);
  };

  const handleSetActiveNavigation = (nav: string) => {
    setSelectedNav(nav);
    setCurrentPage(nav);

    // if (nav === "coustomerinvoice") {
    //   setNewNotificationsCount(0);
    // }
  };
  const dispatch = useDispatch();
  const handleSetActiveNavigationLogout = (nav: string) => {
    log_out();
    dispatch(clearBookingValues());
    //navigate("/");
  };

  const dataNavTop = [
    {
      label: "Dashboard",
      icon: dashboard_icon,
      value: "dashboard",
      navlink: "/Merchant/home",
    },
    {
      label: "Manage My Cars",
      icon: dashboard_car,
      value: "manageMycar",
      navlink: "/Merchant/Managecars",
    },
    {
      label: "List My Cars",
      icon: dashboard_list_my_car,
      value: "listmycar",
      navlink: "/Merchant/ListMyCar",
    },
    // {
    //   label: "Notifications / Inbox",
    //   icon: dashboard_notification,
    //   value: "notification",
    //   navlink: "/Merchant/home",
    // },
    {
      label: "Delivery Executive",
      icon: dashboard_list_my_car,
      value: "deliveryboylist",
      navlink: "/Merchant/DeliveryBoyList",
    },
    // {
    //   label: "Car Status",
    //   icon: dashboard_car_status,
    //   value: "carstatus",
    //   navlink: "/Merchant/home",
    // },
    // {
    //   label: "Trip Status",
    //   icon: dashboard_trip_status,
    //   value: "tripstatus",
    //   navlink: "/Merchant/home",
    // },
    {
      label: "Settings",
      icon: dashboard_settings,
      value: "settings",
      navlink: "/Merchant/Settings",
    },
  ];

  const datanavorder = [
    {
      label: "Customer Order",
      icon: dashboard_customer_invoices,
      value: "coustomerinvoice",
      navlink: "/Merchant/Invoice",
    },
    {
      label: "Accepted Orders",
      icon: dashboard_invoice_approve,
      value: "Acceptedorder",
      navlink: "/Merchant/ApprovedOrders",
    },
    {
      label: "Delivered Orders",
      icon: deliveredIcon,
      value: "Deliveredorder",
      navlink: "/Merchant/Deliveredorder",
    },
    {
      label: "Recollected Orders",
      icon: recollectedIcon,
      value: "recollectedorder",
      navlink: "/Merchant/RecollectedOrder",
    },
  ];
  const dataNavBottom = [
    // {
    //   label: "My Monthly Income",
    //   icon: dashboard_monthly_income,
    //   value: "monthlyincome",
    //   navlink: "/Merchant/home",
    // },
    // {
    //   label: "Transaction Status",
    //   icon: dashboard_transactions_status,
    //   value: "transactionstatus",
    //   navlink: "/Merchant/home",
    // },
    // {
    //   label: "Customer Orders",
    //   icon: dashboard_customer_invoices,
    //   value: "coustomerinvoice",
    //   navlink: "/Merchant/Invoice",
    // },
    // {
    //   label: "Accepted Orders",
    //   icon: dashboard_customer_invoices,
    //   value: "acceptedorders",
    //   navlink: "/Merchant/ApprovedOrders",
    // },
    {
      label: "Track Orders",
      icon: dashboard_customer_invoices,
      value: "trackorders",
      navlink: "/Merchant/AssignedOrderList",
    },
    {
      label: "Deposits & Penalty",
      icon: dashboard_deposit,
      value: "depositandpenality",
      navlink: "/Merchant/DepositandPenality",
    },
    // {
    //   label: "Upgrade",
    //   icon: dashboard_upgrade,
    //   value: "upgrade",
    //   navlink: "/Merchant/home",
    // },
  ];

  return (
    <div
      className={` px-0 bg-black align-items-center sidebar-min-width ${
        selectedNavToggle
          ? "col-1"
          : "col-auto col-md-3 col-xl-2 responsive-sidebar-ab"
      }`}
    >
      {/* <div className="row">
            <div className="col-md-12  text-end toggle-sidebar-btn-div">
                <button className="toggle-sidebar-btn" onClick={handleSetNavToggle}>
                    <img src={sidebar_collapse_icon} alt="" className="toggle-sidebar-btn-icon" />
                </button>
            </div>
        </div> */}
      <div className="sidebar-dash d-flex flex-column align-items-center  px-3 pt-2 text-white min-vh-100 ">
        <div className="  text-end toggle-sidebar-btn-div">
          <button className="toggle-sidebar-btn" onClick={handleSetNavToggle}>
            <img
              src={sidebar_collapse_icon}
              alt=""
              className="toggle-sidebar-btn-icon"
            />
          </button>
        </div>
        <div className="text-center mb-3 mt-2 div-logo-sidebar">
          <Link
            to="#"
            className={` align-items-center ${
              selectedNavToggle
                ? "sidebar-kirkos-icon-toggle"
                : "sidebar-kirkos-icon"
            }`}
          >
            <img
              // src={dashboard_logo_kirkos}
              src={
                selectedNavToggle
                  ? dashboard_logo_kirkos_collapse
                  : kirkos_logo_white_yellow
              }
              width="80%"
              height="80%"
              alt="Logo"
              className="img-fluid ps-2 img-logo-dashboard"
            />
          </Link>
        </div>
        <ul
          className="nav nav-tabs flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start nav-tab-style pt-"
          id="side-bar-menu"
        >
          {dataNavTop.map((item) => (
            <li className="nav-item sidebar-list " key={item.value}>
              <div className="dashboard-side-bar-link-div">
                <Link
                  to={item.navlink}
                  onClick={() => handleSetActiveNavigation(item.value)}
                  className={`nav-link align-middle px-1 dashboard-side-bar-link  ${
                    selectedNav === item.value ? "active-class" : ""
                  }`}
                >
                  <i className="">
                    <img src={item.icon} width="15" height="15" />
                  </i>
                  <span
                    className={`ms-1  label-sidebar label-sidebar-customer ${
                      selectedNavToggle ? "d-none-custom" : ""
                    }`}
                  >
                    {item.label}
                  </span>
                </Link>
              </div>
            </li>
          ))}
        </ul>

        <ul
          className="nav nav-tabs flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start nav-tab-style"
          id="menu1"
        >
          <div className=" align-middle px-0 reporthead">
            <i className=""></i>
            <span
              className={`ms-1  ${selectedNavToggle ? "d-none-custom" : ""}`}
            >
              Order Processing
            </span>
          </div>
          {datanavorder.map((item) => (
            <li className="nav-item sidebar-list " key={item.value}>
              <div className="dashboard-side-bar-link-div">
                <Link
                  to={item.navlink}
                  onClick={() => handleSetActiveNavigation(item.value)}
                  className={`nav-link align-middle px-1 dashboard-side-bar-link  ${
                    selectedNav === item.value ? "active-class" : ""
                  }`}
                >
                  {/* <i className="relative-div">
                    <img src={item.icon} width="15" height="15" />
                    <div className="notification-badge-sidebar icon-hover">
                      2
                    </div>
                  </i> */}
                  <i className="relative-div">
                    <img src={item.icon} width="15" height="15" />
                    {item.value === "coustomerinvoice" &&
                      newNotificationsCount > 0 && (
                        <div className="notification-badge-sidebar icon-hover">
                          {newNotificationsCount}
                        </div>
                      )}
                  </i>

                  <span
                    className={`ms-1  label-sidebar label-sidebar-customer ${
                      selectedNavToggle ? "d-none-custom" : ""
                    }`}
                  >
                    {item.label}
                  </span>
                </Link>
              </div>
            </li>
          ))}
        </ul>
        <ul
          className="nav nav-tabs flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start nav-tab-style"
          id="menu1"
        >
          <div className=" align-middle px-0 reporthead">
            <i className=""></i>
            <span
              className={`ms-1  ${selectedNavToggle ? "d-none-custom" : ""}`}
            >
              Reports
            </span>
          </div>
          {dataNavBottom.map((item) => (
            <li className="nav-item sidebar-list ">
              <div className="dashboard-side-bar-link-div">
                <Link
                  to={item.navlink}
                  onClick={() => handleSetActiveNavigation(item.value)}
                  className={`nav-link align-middle px-1 dashboard-side-bar-link  ${
                    selectedNav === item.value ? "active-class" : ""
                  }`}
                >
                  <i className="">
                    <img src={item.icon} width="15" height="15" />
                  </i>
                  <span
                    className={`ms-1  label-sidebar label-sidebar-customer ${
                      selectedNavToggle ? "d-none-custom" : ""
                    }`}
                  >
                    {item.label}
                  </span>
                </Link>
              </div>
            </li>
          ))}
        </ul>
        <ul
          className="nav nav-tabs flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start nav-tab-style"
          id="menu2"
        >
          <li className="nav-item p-1 sidebar-list">
            <div className="dashboard-side-bar-link-div">
              <Link
                to="/"
                onClick={() => handleSetActiveNavigationLogout("logout")}
                className={`nav-link align-middle px-1 dashboard-side-bar-link  ${
                  selectedNav === "logout" ? "active-class" : ""
                }`}
              >
                <i className="">
                  <img src={dashboard_logout} width="15" height="15" />
                </i>
                <span
                  className={`ms-1  label-sidebar label-sidebar-customer ${
                    selectedNavToggle ? "d-none-custom" : ""
                  }`}
                >
                  Logout
                </span>
              </Link>
            </div>
          </li>

          {/* <li className="">
            <input type="checkbox" id="toggle" className="toggleCheckbox" />
            <label htmlFor="toggle" className="toggleContainer">
              <div>
                {" "}
                <img src={light_mode_icon} width="30" height="30" />
              </div>
              <div>
                {" "}
                <img
                  src={dark_mode_icon}
                  width="23"
                  height="23"
                  style={{ filter: "brightness(1) invert(0)" }}
                  className="pt-2"
                />
              </div>
            </label>
          </li> */}

          {/* <li className="dark_light_mode ps-3">
            <img src={light_mode_icon} width="27" height="27" />
            <span className="ps-2">
              <img src={dark_mode_icon} width="15" height="15" style={{ filter: 'brightness(0) invert(1)', }} />
            </span>
          </li> */}
        </ul>

        {/* <label className="switch">
                        <input type="checkbox" checked/>
                        <span className="slider round"></span>
                    </label>     */}
      </div>
    </div>
  );
};

export default MerchantDashboardSidebar;
