import React from 'react'
import "./style.css"
export const ProductDetails = () => {

  return (
    
    <div className = "col-md-12 " >
        <div className ="row text-white" >
        <div className ="col-10">
        <h2 style={{ textAlign: 'left' }}>Honda CR-V Car Location List History</h2>
        <h6 style={{ textAlign: 'left' }}>Same Car Previous Location Status History</h6>

        <table className="table table-hover  dashboardtable curve-tab ">
            <thead>
                <tr>
                    <th> # </th>
                    <th> Departure </th>
                    <th> Return </th>
                    <th> Status </th>
                    <th> Action </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>09 jan 2024 | B-21L,Satwa,Dubai</td>
                    <td>11 jan 2024 | B-21L,Satwa,Dubai</td>
                    <td style={{ color: 'green' }}>Arrived & HandoverDone</td>
                    <td style={{ color: 'yellow', textDecoration: 'underline' }}>More</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>07 jan 2024 | CT-71L,Clock TW..,Dubai</td>
                    <td>08 jan 2024 | CT-71L,Clock TW..,Dubai</td>
                    <td style={{ color: 'green' }}>Arrived & HandoverDone</td>
                    <td style={{ color: 'yellow', textDecoration: 'underline' }}>More</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>02 jan 2024 | T12-Goldan S..,Dubai</td>
                    <td>05 jan 2024 | T12-Goldan S..,Dubai</td>
                    <td style={{ color: 'green' }}>Arrived & HandoverDone</td>
                    <td style={{ color: 'yellow', textDecoration: 'underline' }}>More</td>
                </tr>
            </tbody>
            </table>   
        </div>
        </div>
    </div>
  )
}


export default ProductDetails;
