import React, { useEffect, useState } from "react";
import {
  dashboard_invoice_approve,
  dashboard_invoice_green_tick,
  dashboard_invoice_order_date,
  dashboard_invoice_order_history,
  dashboard_invoice_payment_done,
  dashboard_vertical_three_dots,
  dashboard_logo_merchant,
  calender_icon,
  info_details_sample_car,
  merchant_dashboard_location_icon,
  pdf,
} from "../../../assets/images";
import { DeliveryBoyData, MerchantInvoiceApprove } from "../../../types";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../services/axios.instance";
import { useMutation } from "@tanstack/react-query";
import { API_URL } from "../../../services/apiConfig";
import { FieldValues } from "react-hook-form";
import ErrorModal from "../../ErrorModal";
import SuccessModal from "../../SuccessModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ImagePopupModal from "../ImagePopupModal";
import { TextRight } from "react-bootstrap-icons";
import ConfirmationModal from "../../ConfirmationModal";

const DeliveryBoyDetails = () => {
  const { customerId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  //-----------------------------------------------------------------------

  const [showAcceptConfirmationModal, setShowAcceptConfirmationModal] =
    useState(false);
  const [showClaimConfirmationModal, setShowClaimConfirmationModal] =
    useState(false);
  const [showRejectConfirmationModal, setShowRejectConfirmationModal] =
    useState(false);
  const [acceptConfirmationMessage, setAcceptConfirmationMessage] =
    useState("");
  const [claimConfirmationMessage, setClaimConfirmationMessage] = useState("");
  const [rejectConfirmationMessage, setRejectConfirmationMessage] =
    useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [claimModalTriggeredBy, setClaimModalTriggeredBy] = useState("");
  const handleOpenConfirmationModal = (message: string) => {
    setAcceptConfirmationMessage(message);
    setShowAcceptConfirmationModal(true);
  };

  const handleOpenClaimConfirmationModal = (
    message: string,
    triggeredBy: string
  ) => {
    setClaimConfirmationMessage(message);
    setClaimModalTriggeredBy(triggeredBy);
    setShowClaimConfirmationModal(true);
  };

  const handleSubmit = () => {
    handleOpenClaimConfirmationModal(
      "Click Confirm to Claim your DeliveryBoy!",
      "approve"
    );
  };

  const handleApproval = useMutation({
    mutationFn: (payload: any) =>
      axiosInstance.post(
        `${API_URL.DELIVERBOY_APPROVAL}`,
        payload,

        {
          headers: {
            location: "o,o",
            language: "english",
          },
        }
      ),
    onSuccess: (successResponse) => {
      console.log("successResponse", successResponse);
      if (successResponse.data.approval_status == "APPROVED") {
        setShowSuccessModal(true);
      } else if (successResponse.data.approval_status == "REJECTED") {
        setShowSuccessModal(true);
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        //setErrorMessage(error?.data?.message);
        //setShowErrorModal(true);
      }
    },
  });

  const handleOpenRejectConfirmationModal = (message: string) => {
    setRejectConfirmationMessage(message);
    setShowRejectConfirmationModal(true);
  };

  const handleReject = () => {
    handleOpenClaimConfirmationModal(
      "Click Confirm to Claim your DeliveryBoy!",
      "reject"
    );
  };

  //----------------------------------------------------------------------------

  //-------------------------------------------

  const {
    data: deliveryboyDetails,
    isLoading,
    isError,
    error,
  } = useQuery<DeliveryBoyData, Error>({
    queryKey: ["executive"],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${API_URL.DELIVERYBOY_DETAILS}${customerId}`
      );
      console.log("res deliveryyyy", response);
      return response.data;
    },
  });
  console.log("kjhgfdghjkljhgfzsdfghjhgf", deliveryboyDetails);

  //----------------------------------------------

  // if (!deliveryBoyDetails) {
  //   return <div>Loading...</div>; // Or any other fallback UI
  // }
  const [showImagePopupModal, setShowImagePopupModal] = useState(false);
  const [imageSource, setImageSource] = useState("");
  //----------------------------------------------
  useEffect(() => {
    if (deliveryboyDetails?.customerDocs?.emiratesId !== undefined) {
      const documentId = deliveryboyDetails.customerDocs.emiratesId.docId;
      console.log("doccccccccccccccccccccccccccccccidddddd", documentId);
      axiosInstance
        .get(`${API_URL.CUSTOMER_DOCUMENTS}/${documentId}`, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          console.log("Response:", response);
          if (response && response.data) {
            console.log("dattatatattatatattata", response.data);
            const contentType = response.headers["content-type"];
            const isPdf = contentType === "application/pdf";
            const isImage = contentType.startsWith("image/jpeg");
            if (isPdf) {
              const blob = new Blob([response.data], {
                type: "application/pdf",
              });
              const objectUrl = URL.createObjectURL(blob);
              const downloadLink = document.getElementById(
                "EMIRATESIDDownloadLink"
              ) as HTMLAnchorElement;
              downloadLink.href = objectUrl;
              downloadLink.style.display = "block";
              downloadLink.download = "EMIRATESID.pdf";
              const EMIRATESID = document.getElementById("EMIRATESID");
              // EMIRATESID?.insertAdjacentText(
              //   "afterbegin",
              //   `Size: ${Number(response.data.byteLength) / 1000} KB`
              // );
              const imageElement = document.getElementById(
                "EMIRATESID_IMAGE"
              ) as HTMLImageElement;
              // imageElement.src = imageUrl;

              imageElement.src = pdf;
            } else if (isImage) {
              const blob = new Blob([response.data], { type: contentType });
              const imageUrl = URL.createObjectURL(blob);
              const imageElement = document.getElementById(
                "EMIRATESID_IMAGE"
              ) as HTMLImageElement;
              // imageElement.src = imageUrl;

              imageElement.src = imageUrl;
              imageElement.onclick = () => {
                setImageSource(imageUrl);
                setShowImagePopupModal(true);
              };

              const imageSizeKB = response.data.byteLength / 1000;
              console.log("Image Size:", imageSizeKB, "KB");
            }
          } else {
            console.error("Response or data is undefined");
          }
        });
    }
  }, [deliveryboyDetails]);

  useEffect(() => {
    if (deliveryboyDetails?.customerDocs?.DRIVING_LICENSE_FRONT !== undefined) {
      const documentId =
        deliveryboyDetails.customerDocs.DRIVING_LICENSE_FRONT.docId;
      axiosInstance
        .get(`${API_URL.CUSTOMER_DOCUMENTS}/${documentId}`, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          console.log("Response:", response);
          if (response && response.data) {
            console.log("dattatatattatatattata", response.data);
            const contentType = response.headers["content-type"];
            const isPdf = contentType === "application/pdf";
            const isImage = contentType.startsWith("image/jpeg");
            console.log("PDF ::: " + isPdf);
            if (isPdf) {
              const blob = new Blob([response.data], {
                type: "application/pdf",
              });
              const objectUrl = URL.createObjectURL(blob);
              const downloadLink = document.getElementById(
                "LICENSEDownloadLink"
              ) as HTMLAnchorElement;
              downloadLink.href = objectUrl;
              downloadLink.style.display = "block";
              downloadLink.download = "LICENSE.pdf";
              const LICENSE = document.getElementById("LICENSE");
              // LICENSE?.insertAdjacentText(
              //   "afterbegin",
              //   `Size: ${Number(response.data.byteLength) / 1000} KB`
              // );
              const imageElement = document.getElementById(
                "LICENSE_IMAGE"
              ) as HTMLImageElement;
              // imageElement.src = imageUrl;

              imageElement.src = pdf;
            } else if (isImage) {
              const blob = new Blob([response.data], { type: contentType });
              const imageUrl = URL.createObjectURL(blob);
              const imageElement = document.getElementById(
                "LICENSE_IMAGE"
              ) as HTMLImageElement;
              // imageElement.src = imageUrl;

              imageElement.src = imageUrl;
              imageElement.onclick = () => {
                setImageSource(imageUrl);
                setShowImagePopupModal(true);
              };

              const imageSizeKB = response.data.byteLength / 1000;
              console.log("Image Size:", imageSizeKB, "KB");
            }
          } else {
            console.error("Response or data is undefined");
          }
        });
    }
  }, [deliveryboyDetails]);

  useEffect(() => {
    if (deliveryboyDetails?.customerDocs?.DRIVING_LICENSE_BACK !== undefined) {
      const documentId =
        deliveryboyDetails.customerDocs.DRIVING_LICENSE_BACK.docId;
      console.log("doccccccccccccccccccccccccccccccidddddd", documentId);
      axiosInstance
        .get(`${API_URL.CUSTOMER_DOCUMENTS}/${documentId}`, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          console.log("Response:", response);
          if (response && response.data) {
            console.log("dattatatattatatattata", response.data);
            const contentType = response.headers["content-type"];
            const isPdf = contentType === "application/pdf";
            const isImage = contentType.startsWith("image/jpeg");
            if (isPdf) {
              const blob = new Blob([response.data], {
                type: "application/pdf",
              });
              const objectUrl = URL.createObjectURL(blob);
              const downloadLink = document.getElementById(
                "LICENSEBACKDownloadLink"
              ) as HTMLAnchorElement;
              downloadLink.href = objectUrl;
              downloadLink.style.display = "block";
              downloadLink.download = "LICENSEBACK.pdf";
              const LICENSEBACK = document.getElementById("LICENSEBACK");
              // EMIRATESID?.insertAdjacentText(
              //   "afterbegin",
              //   `Size: ${Number(response.data.byteLength) / 1000} KB`
              // );
              const imageElement = document.getElementById(
                "LICENSEBACK_IMAGE"
              ) as HTMLImageElement;
              // imageElement.src = imageUrl;

              imageElement.src = pdf;
            } else if (isImage) {
              const blob = new Blob([response.data], { type: contentType });
              const imageUrl = URL.createObjectURL(blob);
              const imageElement = document.getElementById(
                "LICENSEBACK_IMAGE"
              ) as HTMLImageElement;
              // imageElement.src = imageUrl;

              imageElement.src = imageUrl;
              imageElement.onclick = () => {
                setImageSource(imageUrl);
                setShowImagePopupModal(true);
              };

              const imageSizeKB = response.data.byteLength / 1000;
              console.log("Image Size:", imageSizeKB, "KB");
            }
          } else {
            console.error("Response or data is undefined");
          }
        });
    }
  }, [deliveryboyDetails]);

  //---------------------------------------------------------------

  return (
    <div className="row">
      <div className="col-md-12 d-flex justify-content-center pt-5 pb-2">
        <p className="info-text mt-1" id="license"></p>
        {deliveryboyDetails?.approvalStatus === "PENDING" && (
          <div className="invoice-details-btn-div text-end">
            <button className="invoice-approve-btn " onClick={handleSubmit}>
              {" "}
              <img src={dashboard_invoice_approve} height={"14px"} alt="" />
              &nbsp;APPROVE
            </button>

            <button className="invoice-reject-btn" onClick={handleReject}>
              {" "}
              <span className="invoice-paid-failed-style"> &#10006;</span>
              &nbsp; REJECT
            </button>
          </div>
        )}
      </div>
      <div className="col-md-12 text-start div-bg-invoice-details">
        <div className="card card-bg-invoice-details">
          <div className="card-body px-5">
            <div className="row  pt-4 pb-3">
              <div className="col-md-6">
                <div className="col-md-12 pt-3">
                  <div className="invoice-text-yellow">
                    {" "}
                    DELIVERYBOY DETAILS{" "}
                  </div>
                </div>
                <div className="col-md-12 invoice-div-head pt-3">NAME</div>
                <div className="col-md-12 invoice-div-data">
                  {deliveryboyDetails?.firstName}&nbsp;
                  {deliveryboyDetails?.lastName}
                </div>
                <div className="col-md-12 invoice-div-head pt-3">GENDER</div>
                <div className="col-md-12 invoice-div-data">
                  {deliveryboyDetails?.gender}
                </div>
                <div className="col-md-12 invoice-div-head pt-3">
                  CONTACT NUMBER
                </div>
                <div className="col-md-12 invoice-div-data">
                  {deliveryboyDetails?.phoneNumber}
                </div>

                <div className="col-md-12 invoice-div-head pt-3">
                  LICENSE NUMBER
                </div>
                <div className="col-md-12 invoice-div-data">
                  {deliveryboyDetails?.licence}
                </div>
                {/* <div className="col-md-12 invoice-div-head pt-3">
                  EMRATES ID
                </div>
                <div className="col-md-12 invoice-div-data">
                  {deliveryboyDetails?.licence}
                </div> */}
                {/* <div className="col-md-12 invoice-div-head pt-3">
                  PHONE NUMBER
                  <div className="col-md-12 invoice-div-data">73******14</div>
                </div> */}

                <div className="col-md-12 invoice-div-head pt-3">ADDRESS</div>
                <div className="col-md-12 invoice-div-data">
                  {deliveryboyDetails?.profileAddress?.address}
                </div>
                <div className="col-md-12 invoice-div-head pt-3">STATE</div>
                <div className="col-md-12 invoice-div-data">
                  {deliveryboyDetails?.profileAddress?.state}
                </div>
                <div className="col-md-12 invoice-div-head pt-3">COUNTRY</div>
                <div className="col-md-12 invoice-div-data">
                  {deliveryboyDetails?.profileAddress?.country}
                </div>
              </div>
              <div className="col-md-6 border-start-invoice-details">
                <div className="col-md-12 pt-5">
                  <div className="invoice-text-yellow"> DOCUMENTS</div>
                  <div className="card invoice-card-inner">
                    <div className="card-body">
                      <div className="row">
                        <div id="license_container" className="col-md-6 mb-2">
                          <p className="text-white">LICENSE Frontside: </p>

                          <img
                            id="LICENSE_IMAGE"
                            style={{ width: 75, height: 75 }}
                          />
                          <p id="LICENSE"></p>
                          <a
                            className="download-img"
                            id="LICENSEDownloadLink"
                            style={{ display: "none" }}
                            title="Download LICENSE PDF"
                          >
                            Download LICENSE PDF
                          </a>
                        </div>
                        <div id="license_container" className="col-md-6 mb-2">
                          <p className="text-white">LICENSE Backside: </p>

                          <img
                            id="LICENSEBACK_IMAGE"
                            style={{ width: 75, height: 75 }}
                          />
                          <p id="LICENSEBACK"></p>
                          <a
                            className="download-img"
                            id="LICENSEBACKDownloadLink"
                            style={{ display: "none" }}
                            title="Download LICENSEBACK PDF"
                          >
                            Download LICENSE PDF
                          </a>
                        </div>
                        <div id="emirites_container" className="col-md-6 mb-2">
                          <p className="text-white">EMIRATES: </p>

                          <img
                            id="EMIRATESID_IMAGE"
                            style={{ width: 75, height: 75 }}
                          />
                          <p id="EMIRATESID"></p>

                          <a
                            className="download-img"
                            id="EMIRATESIDDownloadLink"
                            title="Download EMIRATESID PDF"
                            style={{ display: "none" }}
                          >
                            Download EMIRATESID PDF
                          </a>
                        </div>
                      </div>
                    </div>

                    {/* </div> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        isOpen={showAcceptConfirmationModal}
        onClose={() => setShowAcceptConfirmationModal(false)}
        onConfirm={() => {
          handleApproval.mutate({
            status: "APPROVED",
            customer_id: customerId,
            acknowledgement: "approve",
          });
          //handleApproval.mutate();
          setShowAcceptConfirmationModal(false);
          // setShowSuccessModal(true);
        }}
        message={acceptConfirmationMessage}
      />

      <ConfirmationModal
        isOpen={showClaimConfirmationModal}
        onClose={() => setShowClaimConfirmationModal(false)}
        onConfirm={() => {
          if (claimModalTriggeredBy === "approve") {
            handleApproval.mutate({
              status: "CLAIMED",
              customer_id: customerId,
              acknowledgement: "claim",
            });
            //setShowAcceptConfirmationModal(true);
            handleOpenConfirmationModal("Are you sure you want to approve?");
          } else if (claimModalTriggeredBy === "reject") {
            // setShowRejectConfirmationModal(true);
            handleApproval.mutate({
              status: "CLAIMED",
              customer_id: customerId,
              acknowledgement: "claim",
            });
            handleOpenRejectConfirmationModal(
              "Are you sure you want to reject?"
            );
          }
          setShowClaimConfirmationModal(false);
        }}
        message={claimConfirmationMessage}
      />

      <ConfirmationModal
        isOpen={showRejectConfirmationModal}
        onClose={() => setShowRejectConfirmationModal(false)}
        onConfirm={() => {
          //handleRejection.mutate();
          handleApproval.mutate({
            status: "REJECTED",
            customer_id: customerId,
            acknowledgement: "reject",
          });
          setShowRejectConfirmationModal(false);
        }}
        message={rejectConfirmationMessage}
      />

      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onNav={() => {
          navigate("/Merchant/DeliveryBoyList");
        }}
        message=""
      />

      <ImagePopupModal
        isOpen={showImagePopupModal}
        //  onClose={onCloseImagePopupModal}
        src={imageSource}
        onClose={() => setShowImagePopupModal(false)}
        message={""}
      />
    </div>
  );
};

export default DeliveryBoyDetails;
