import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useForm } from "react-hook-form";
import MerchantProgressbar from "../../../components/MerchantProgressbar";

import {
  kirkos_merchant_logo,
  kirkos_merchant_red_car,
  kirkos_merchant_yellow_car,
  kirkos_merchant_white_car,
  welcome_icon,
  qr_code,
  stores,
  white_star_icon,
  kirkos_merchant_profile,
  kirkos_merchant_arrow,
} from "../../../assets/images";
import { Link } from "react-router-dom";

const GrageOnboardWelcome = () => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
    trigger,
  } = useForm({ mode: "onChange" });

  return (
    <>
      <div className="progressbar-custom">
        <MerchantProgressbar currentStage={7} />
      </div>
      <div className="row text-start">
        <div className="d-flex align-items-start mt-4 pt-5 ">
          <img
            src={white_star_icon}
            className="custom-img-welcome"
            alt="Icon"
          />
          <p className="textsign-custom text-light ms-3 pt-1">
            Welcome to Kirkos
          </p>
        </div>
        <p className="subtextwels">
          You have successfully completed the sign-up Process
        </p>
        <div className="col-md-12">
          <div className=" mt-2">
            <p className="textwels">
              Proceed to{" "}
              <Link to="/GarageOnboard" className="merchant_account_link_text">
                My Account
              </Link>{" "}
              to showcase <br></br> Cars available for rent
            </p>
          </div>
        </div>

        <div className="row d-md-flex qrscanrow">
          <div className="col-12 col-md-3 mb-3 mb-md-0">
            <img className="qrscanstyle" src={qr_code} alt="QR Code" />
            <p className="scan_text">SCAN NOW</p>
          </div>
          <div className="col-12 col-md-6 storeimage">
            <p className="app_subtextwel">
              You can also download the <br></br>KIRKOS App as well{" "}
            </p>
            <img className="storestyle img-fluid" src={stores} alt="store" />
          </div>
        </div>
      </div>
    </>
  );
};

export default GrageOnboardWelcome;
