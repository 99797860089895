import iconcus from "../assets/images/kirkos_logo.svg";
import { useState, useRef, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Eye, EyeSlash } from "react-bootstrap-icons";

import {
  car_home,
  car_sign_in,
  kirkos_logo,
  car_sign_up,
  icon_black,
  icon_normal,
  kirkos_merchant_white_car,
  kirkos_merchant_yellow_car,
  kirkos_merchant_red_car,
  kirkos_merchant_logo,
  kirkos_merchant_profile,
} from "../../../assets/images";
import {
  CustomCheckBox,
  CustomInput,
  ErrorModal,
  MerchantOnboardLeftSide,
  Progressbar,
} from "../../../components";
import { FieldValues, useForm } from "react-hook-form";
import rules from "../../../rules";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { LoginData, UserData, VerifyOtp } from "../../../types";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "../../../Pages/Customer/signin/styles.css";
import RegistrationModal from "../../../components/RegistrationModal";
import { getTotp } from "../../../utils/totpUtil";
import MerchantProgressbar from "../../../components/MerchantProgressbar";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../../Redux/Auth/authSlice";

const LoginGarage = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false); // State to manage modal visibility
  const [errorMessage, setErrorMessage] = useState(""); // State to store error message
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({ mode: "onChange" });

  let access_token: string = "";
  let refresh_token: string = "";
  const decodeToken = (token: any) => {
    if (token) {
      return JSON.parse(atob(token.split(".")[1]));
    } else {
      return null;
    }
  };

  const handleLogin = useMutation({
    mutationFn: (userData: LoginData) =>
      axiosInstance.post(API_URL.LOGIN, userData),
    onSuccess: (successResponse: any) => {
      console.log("successResponse", successResponse);
      if (successResponse.status && successResponse?.data?.message) {
        alert(successResponse?.data?.message);
      }
      //const access_token = successResponse?.data?.access_token;
      access_token = successResponse?.data?.access_token;
      refresh_token = successResponse?.data?.refresh_token;
      const decodedToken: any = decodeToken(access_token);
      const access = decodedToken?.permission;
      const lastLevel = successResponse?.data?.last_level;
      if (access === "partial-access" && lastLevel === "1") {
        navigate("/GarageOnboard/business");
      } else if (access === "partial-access" && lastLevel === "2") {
        navigate("/GarageOnboard/LicenseDetails");
      } else if (access === "partial-access" && lastLevel === "3") {
        navigate("/GarageOnboard/KYCDetails");
      } else if (access === "partial-access" && lastLevel === "999") {
        setShowSuccessModal(true);
      } else if (access === "full-access" && lastLevel === "999") {
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("refresh_token", refresh_token);

        saveTokenToLocalStorage();
        dispatch(loginSuccess());
        navigate("/Garage");
      } else {
        console.log("access denied");
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setErrorMessage(error?.data?.message);
        setShowErrorModal(true);
      }
    },
  });

  const saveTokenToLocalStorage = () => {
    if (isChecked && access_token !== null && refresh_token !== null) {
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
    }
  };

  const [isChecked, setChecked] = useState(false);

  const handleCheckBoxChange = () => {
    setChecked(!isChecked);
    if (isChecked) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
    } else {
      saveTokenToLocalStorage();
    }
  };

  const combinedValidationRule = {
    validate: (value: any) => {
      const isValidEmail =
        /^(([^<>()[\]\\.+,;:\s@"']+(\.[^<>()[\]\\.,+;:\s@"']+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        );
      const isValidPhoneNumber = /^5\d{8}$/.test(value);

      if (!isValidEmail && !isValidPhoneNumber) {
        return "Invalid email address or phone number";
      }
      return true;
    },
  };

  const onHandleSubmit = () => {
    const userData: LoginData = {
      username: email,
      password: password,
      userType: "GARAGE_USER",
    };

    handleLogin.mutate(userData);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className=" pt-5 pe-2 text-start">
        <div className=" d-flex  pt-5 ">
          <img
            src={kirkos_merchant_profile}
            className="icon-img img-fluid"
            alt="Icon"
          />
          <p className=" merchant_login_text text-light ps-3 pt-3">
            Garage Sign In
          </p>
        </div>
        <div className="d-flex">
          <div className="merchant_newkirkos_text pt-1">New To KIRKOS?</div>
          <Link
            to="/GarageOnboard/email"
            className="merchant_login_signup_text ps-2 "
          >
            Sign up
          </Link>
          <a
            href="/"
            style={{
              color: "#f4b652",
              textDecoration: "underline",
              marginLeft: "4%",
              fontFamily: "Poppins, sans-serif",
              fontStyle: "initial",
              display: "inline",
            }}
          >
            Home{" "}
          </a>
        </div>

        <p className="merchant_login_headingStyle1 pt-4">
          Username
          <span className="merchanttopstar">*</span>
        </p>

        <div className="inputContainerStyle">
          <CustomInput
            inputName="email"
            inputType="email"
            className="merchant_login_input_field"
            placeholder="email-id or phone number"
            editable={true}
            onInputChange={(value: string) => setEmail(value)}
            control={control}
            rules={combinedValidationRule}
          />
        </div>
        <p className="merchant_login_headingStyle3 pt-4">
          Password<span className="merchanttopstar">*</span>
        </p>
        <div className="d-flex">
          <CustomInput
            inputName="password"
            inputType={showPassword ? "text" : "password"}
            className="merchant_login_input_field"
            placeholder="xxxxxx"
            editable={true}
            onInputChange={(value: string) => setPassword(value)}
            control={control}
            rules={rules.AuthRules.password}
          />
          <span
            className="fs-5 password_eye"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <EyeSlash /> : <Eye />}
          </span>
        </div>
        {/* <div className="d-flex pt-5 "> */}
        {/* <CustomCheckBox
            isChecked={isChecked}
            onChange={handleCheckBoxChange}
          /> */}

        {/* <span
            className="merchant_login_rememberme_text flex-nowrap d-flex"
            style={{ textWrap: "nowrap" }}
          >
            Remember me
          </span> */}
        {/* <span
            className="flex-nowrap d-flex text-end"
            style={{ width: "100%" }}
          >
            <Link
              to="/GarageOnboard/ForgotPassWord"
              className="merchant_forgot_link"
              style={{ textWrap: "nowrap", width: "100%" }}
            >
              Forgot Password?
            </Link>
          </span>
        </div> */}
        <div className="col-12 text-end pt-5">
          <Link
            to="/GarageOnboard/ForgotPassWord"
            className="merchant_forgot_link"
            style={{ textWrap: "nowrap", width: "100%" }}
          >
            Forgot Password?
          </Link>
        </div>
        <button
          className="btn all_button_style text-black mb-5 btn "
          onClick={handleSubmit(onHandleSubmit)}
          disabled={!isValid}
        >
          SIGN IN
        </button>
      </div>
      <RegistrationModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onNav={() => {
          navigate("/");
        }}
        message="Waiting for backoffice approval..."
      />
      <ErrorModal
        isOpen={showErrorModal}
        //  onClose={onCloseErrorModal}
        onClose={() => setShowErrorModal(false)}
        message="The credentials you entered are invalid"
      />
    </>
  );
};

export default LoginGarage;
