import React, { useEffect, useState } from "react";
import "./MerchantDashboardCarListings.css";
import { ArrowsAngleExpand, ListUl } from "react-bootstrap-icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Car, CarMain, ProductList } from "../../../types";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { Link } from "react-router-dom";

const MerchantDashboardCarListings = () => {
  const accessToken = localStorage.getItem("access_token");
  const [carList, setCarList] = useState<Car[]>([]);

  // const decodeToken = (token: any) => {
  //   return JSON.parse(atob(token.split(".")[1]));
  // };

  const decodeToken = (token: any) => {
    if (token) {
      return JSON.parse(atob(token.split(".")[1]));
    } else {
      return null;
    }
  };

  const decodedToken = decodeToken(accessToken);
  // const merchantId = decodedToken["merchant-id"];
  // const businessArea = decodedToken["planet"];
  const merchantId =
    decodedToken && decodedToken["merchant-id"]
      ? decodedToken["merchant-id"]
      : null;

  // Check if decodedToken exists and has the "planet" property
  const businessArea =
    decodedToken && decodedToken["planet"] ? decodedToken["planet"] : null;

  //-----------------------
  const mutation = useMutation({
    mutationFn: (userData: ProductList) =>
      axiosInstance.post(API_URL.PRODUCT_LIST, userData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),

    onSuccess: (successResponse) => {
      console.log("successResponseproductlist", successResponse);
      const filteredCarList = successResponse?.data.content.filter(
        (car: any) => car.record_status === 1
      );
      setCarList(filteredCarList);
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        //alert(error?.data?.message);
      }
    },
  });

  useEffect(() => {
    mutation.mutate({
      merchant_id: merchantId,
      business_area: businessArea,
      status: "APPROVED",
    });
  }, []);

  //--------------------------
  return (
    <div className="col-md-7">
      <div className="p-4 merchant_dashboard_box_style shadow-sm rounded-5 text-white">
        <div className="d-flex">
          <ListUl size={24} color="white" />
          <h4 className="text-start listings_heading_text ps-3">
            My car Listing/Status
          </h4>
          <div className="col-5 text-end pb-2">
            <div className="pb-2">
              <ArrowsAngleExpand size={"12px"} />
            </div>
            <Link to="/Merchant/Managecars" className="ps-5 listing_see_all">
              See all
            </Link>
          </div>
        </div>

        {carList &&
          carList.slice(0, 3).map((car, index) => (
            <div key={index} className="row mycar_listings_row ms-2">
              <div className="col-3">
                <div>
                  {index + 1} {car?.parent_category_id}{" "}
                  <span className="listings_grey_text">
                    {car?.product_details_car?.car_model}
                  </span>
                </div>
              </div>

              <div className="col-3">
                <div className="listings_green_text">{car?.status}</div>
              </div>

              <div className="col-3">
                <div className="listings_grey_text">
                  {car?.product_details_car?.car_color}
                </div>
              </div>

              <div className="col">
                <div className="listings_reg_text">
                  Reg No: {car?.product_details_car?.register_number} DUBAI
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default MerchantDashboardCarListings;
