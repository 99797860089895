import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { SignInScreen, SignUpScreen } from "../Pages/Customer";
import HomePage from "../Pages/Customer/homepage";
import CustomerSignUpFormBoot from "../Pages/Customer/signupboot";
import OtpScreen from "../components/otp";
import WelcomePage from "../components/welcomepage";
import SignUpDetails from "../Pages/Customer/signupdetails";
import Welcome from "../components/welcome";
import MerchantSignUp from "../Pages/Merchant/MerchantSignUp_FIrstPage";
import Password from "../Pages/Merchant/MerchantSignUp_SecondPage/MerchantPassword";
import Welcomemerchant from "../Pages/Merchant/Merchant_WelcomePage/MerchantWelcome";
import Loginkirkos from "../Pages/Merchant/Merchant_LoginPage/MerchantKirkoslogin";
import KYCdetails from "../Pages/Merchant/KYCdetails";
import LicenseDetails from "../Pages/Merchant/licensedetails";
import MerchantSignUpFormBoot from "../Pages/Merchant/MerchantSignUp_FIrstPage";
import MerchantOtpScreen from "../Pages/Merchant/MerchantOtpPage.tsx/MerchantOtp";
import MerchantBusinessScreen from "../Pages/Merchant/MerchantSignUp_ThirdPage/MerchantBusiness";
import MerchantSignUpViaPhone from "../Pages/Merchant/MerchantSignUp_ViaPhone";
import MerchantOtpScreenViaPhone from "../Pages/Merchant/MerchantOtpPage_ViaPhone";
import MerchantDashboard from "../Pages/Merchant/MerchantDashboard";
import {
  CreateProductCarInfo,
  CreateProductInsurance,
  LocationStatus,
  CustomerDashBoardMyAccount,
  MainPage,
  InsuranceHolderVehicleInspection,
  FinalReport,
  VehicleInspection,
  ProductDetails,
  BookingProcess,
  NonInsuranceHolder,
  NonInsuranceHolderSecondPage,
  RentalSummarySecondPage,
  ManageMyProfile,
  RentalSummaryCarousel,
  RentDeals,
  Merchantmanagemyprofile,
  Merchantsettings,
  CoustomerInvoiceList,
  CoustomerInvoiceDetails,
  InsuranceHolderRentalConfirm,
  Merchantdeposit,
  Merchantdepositsdetails,
  GarageHome,
  LoginGarage,
  GarageSignUpEmail,
  GarageSignUpViaPhone,
  GarageOtpScreen,
  GaragePassword,
  GarageBusinessScreen,
  GrageOnboardWelcome,
  GarageOnboardKYCDetail,
  GarageOnboardLicenseDetails,
  GarageOnboardForgotPassWord,
  GarageOnboardForgotOtp,
  GarageOnboardNewPassword,
  GarageOtpPageViaPhone,
  GarageInsuranceOrderDetails,
  GarageInsuranceCalimList,
  Deliveredorder,
  DeliveredorderDetails,
  AcceptedorderDetails,
  RecollectedOrder,
  RecollectedOrderDetails,
  RentDealsDetails,
  BookingCalender,
  AcceptedorderDetailsEdit,
  DeliveryExecutiveAsign,
} from "../components";
import { ManageCarList } from "../components";
import { MerchantDashboardFullComponents } from "../components";

import CustomerDashboard from "../Pages/Customer/CustomerDashboard";
import Customerforgotpassword from "../Pages/Customer/forgotpassword";
import Customerotpviaforgot from "../Pages/Customer_Otp_ViaForgot";
import Customernewpassword from "../Pages/Customer_Newpassword";

import Merchantforgotpassword from "../Pages/Merchant/Merchant_ForgotPassword";
import MerchantDashboardMyIncome from "../components/MerchantDashboard/MerchantDashboardMyIncome";
import MerchantManageMyCars from "../components/MerchantDashboard/MerchantManageMyCars";
import Merchantotpviaforgot from "../Pages/Merchant/Merchant_Otp_ViaForgot";
import Merchantnewpasssword from "../Pages/Merchant/Merchant_Newpassword";
import SelectPaymentMethod from "../components/SelectPaymentMethod";
import Odoo from "../Pages/Merchant/Odoo";
import Processpayment from "../components/ProcessPayment";
import Customernotification from "../components/CustomerDashBoardNotifications";
import Customersettings from "../components/CustomerDashBoard/CustomerSettings";
import DeliveryBoyList from "../components/MerchantDashboard/DeliveryBoyList";
import DeliveryBoyDetails from "../components/MerchantDashboard/DeliveryBoyDetails";
import ApprovedDeliveryBoysList from "../components/MerchantDashboard/ApprovedDeliveryBoysList";
import AssignedOrderList from "../components/MerchantDashboard/AssignedOrderList";
import TrackOrderDetails from "../components/MerchantDashboard/TrackOrderDetails";
import ApprovedOrders from "../components/MerchantDashboard/ApprovedOrders";
import GarageDashBoard from "../Pages/Garage/GarageDashBoard";
import GarageOnboard from "../Pages/Garage/GarageOnboard";
import RecollectionDeliveryBoysList from "../components/MerchantDashboard/RecollectionDeliveryBoysList";
import GarageProfile from "../components/GarageDashBoard/GarageProfile";

const RouterComponent: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/merchant/signup" element={<MerchantSignUpFormBoot />} />
        <Route
          path="/merchant/signup/viaphone"
          element={<MerchantSignUpViaPhone />}
        />
        <Route
          path="/merchant/signup/otpmerchant/viaphone"
          element={<MerchantOtpScreenViaPhone />}
        />
        <Route
          path="/merchant/signup/otpmerchant"
          element={<MerchantOtpScreen />}
        />
        <Route path="/merchant/signup/password" element={<Password />} />
        <Route
          path="/merchant/signup/businesspage"
          element={<MerchantBusinessScreen />}
        />
        <Route path="/Customer/Noninsurance" element={<NonInsuranceHolder />} />
        <Route
          path="/merchant/signup/licensedetails"
          element={<LicenseDetails />}
        />
        <Route path="/merchant/signup/kycdetails" element={<KYCdetails />} />
        <Route path="/merchant/signup/welcome" element={<Welcomemerchant />} />

        <Route
          path="/merchant/signup/businesspage"
          element={<MerchantBusinessScreen />}
        />
        <Route path="/merchantsignup" element={<MerchantSignUp />} />
        <Route path="/signIn" element={<SignInScreen />} />
        <Route path="/SignInMerchant" element={<Loginkirkos />} />
        <Route path="/Odoo" element={<Odoo />} />

        <Route path="/welcome" element={<Welcome />} />
        <Route path="/SignUp" element={<CustomerSignUpFormBoot />} />
        <Route path="SignUp/otpscreen" element={<OtpScreen />} />
        <Route path="forgot/otpscreen" element={<Customerotpviaforgot />} />
        <Route path="/forgotpassword" element={<Customerforgotpassword />} />
        <Route path="/newpassword" element={<Customernewpassword />} />
        <Route path="/merchant/password" element={<Password />} />
        <Route path="/merchant/welcome" element={<Welcomemerchant />} />
        <Route path="/merchant/login" element={<Loginkirkos />} />
        <Route
          path="/merchant/income"
          element={<MerchantDashboardMyIncome />}
        />
        <Route path="/merchant/manage" element={<MerchantManageMyCars />} />
        <Route
          path="/merchant/forgot/otppage"
          element={<Merchantotpviaforgot />}
        />
        <Route
          path="/merchant/newpassword"
          element={<Merchantnewpasssword />}
        />

        <Route
          path="/Merchant/Income"
          element={<MerchantDashboardMyIncome />}
        />
        <Route
          path="SignUp/otpscreen/signupdetails"
          element={<SignUpDetails />}
        />
        <Route
          path="SignUp/otpscreen/signupdetails/welcome"
          element={<WelcomePage />}
        />
        <Route path="/SignIn/home" element={<Welcome />} />
        <Route
          path="/merchant/forgotpassword"
          element={<Merchantforgotpassword />}
        />

        {/* <Route path="/MerchantDashboard" element={<MerchantDashboard />} /> */}
        <Route path="/Merchant" element={<MerchantDashboard />}>
          <Route
            path="/Merchant/Home"
            element={<MerchantDashboardFullComponents />}
          />
          <Route path="/Merchant/Managecars" element={<ManageCarList />} />
          <Route path="/Merchant/ListMyCar" element={<ManageCarList />} />

          <Route
            path="/Merchant/Managecars/CarInfo"
            element={<CreateProductCarInfo />}
          />

          <Route
            path="/Merchant/Managecars/Insurance"
            element={<CreateProductInsurance />}
          />
          <Route
            path="/Merchant/Managecars/List/:productId"
            element={<MerchantManageMyCars />}
          />
          <Route path="/Merchant/LocationStatus" element={<LocationStatus />} />
          <Route
            path="/Merchant/ManageMyProfile"
            element={<Merchantmanagemyprofile />}
          />
          <Route path="/Merchant/Settings" element={<Merchantsettings />} />

          {/* ------------ order listing -------------- */}
          <Route path="/Merchant/Invoice" element={<CoustomerInvoiceList />} />
          <Route path="/Merchant/ApprovedOrders" element={<ApprovedOrders />} />
          <Route path="/Merchant/Deliveredorder" element={<Deliveredorder />} />
          <Route
            path="/Merchant/RecollectedOrder"
            element={<RecollectedOrder />}
          />
          {/* ------------ order listing -------------- */}
          {/* ------------ order details --------------------- */}
          <Route
            path="/Merchant/Invoice/Details"
            element={<CoustomerInvoiceDetails />}
          />
          <Route
            path="/Merchant/Accepted/Details"
            element={<AcceptedorderDetails />}
          />
          <Route
            path="/Merchant/Accepted/Edit"
            element={<AcceptedorderDetailsEdit />}
          />
          <Route
            path="/Merchant/Accepted/Asign"
            element={<DeliveryExecutiveAsign />}
          />
          <Route
            path="/Merchant/Delivered/Details"
            element={<DeliveredorderDetails />}
          />
          <Route
            path="/Merchant/Recollected/Details"
            element={<RecollectedOrderDetails />}
          />

          {/* ------------ order details --------------------- */}

          <Route
            path="/Merchant/DepositandPenality"
            element={<Merchantdeposit />}
          />
          <Route
            path="/Merchant/DepositandPenality/details"
            element={<Merchantdepositsdetails />}
          />
          <Route
            path="/Merchant/TrackOrderDetails"
            element={<TrackOrderDetails />}
          />
          <Route
            path="/Merchant/DeliveryBoyList"
            element={<DeliveryBoyList />}
          />
          <Route
            path="/Merchant/DeliveryBoyList/details/:customerId"
            element={<DeliveryBoyDetails />}
          />
          <Route
            path="/Merchant/ApprovedDeliveryBoysList"
            element={<ApprovedDeliveryBoysList />}
          />
          <Route
            path="/Merchant/RecollectioneliveryBoysList/Asign"
            element={<RecollectionDeliveryBoysList />}
          />
          <Route
            path="/Merchant/AssignedOrderList"
            element={<AssignedOrderList />}
          />
        </Route>

        <Route path="/Customer" element={<CustomerDashboard />}>
          <Route
            path="/Customer/InsuranceHolderVehicleInspection"
            element={<InsuranceHolderVehicleInspection />}
          />
          <Route path="/Customer/FinalReport" element={<FinalReport />} />
          <Route
            path="/Customer/RentalConfirm"
            element={<InsuranceHolderRentalConfirm />}
          />
          <Route
            path="/Customer/VehicleInspection"
            element={<VehicleInspection />}
          />
          <Route
            path="/Customer/NonInsuranceHolder"
            element={<NonInsuranceHolder />}
          />
          <Route path="/Customer/processpayment" element={<Processpayment />} />
          <Route
            path="/Customer/notifications"
            element={<Customernotification />}
          />
          <Route
            path="/Customer/selectpayment"
            element={<SelectPaymentMethod />}
          />

          <Route
            path="/Customer/BP_Non_Holder"
            element={<NonInsuranceHolder />}
          />
          <Route
            path="/Customer/NonInsurence"
            element={<NonInsuranceHolderSecondPage />}
          />
          <Route path="/Customer/BookingProcess" element={<BookingProcess />} />
          {/* <Route
            path="/Customer/RentalSummary_secondPage"
            element={
              <RentalSummarySecondPage
                daysDifference={0}
                totalAmount={0}
                childSeatAmount={0}
                paiInsuranceAmount={0}
                onDataFromRentalSummary={function (data: any): void {
                  throw new Error("Function not implemented.");
                }}
                carImage={""}
                carModel={""}
                seat={0}
                fuelType={""}
                cdwAmount={0}
                scdwAmount={0}
              />
            }
          /> */}
          {/* <Route
            path="/Customer/RentalSummaryCarousel"
            element={<RentalSummaryCarousel />}
          /> */}

          <Route
            path="/Customer/ManageMyProfile"
            element={<ManageMyProfile />}
          />
          <Route path="/Customer/RentDeals" element={<RentDeals />} />
          <Route
            path="/Customer/RentDealsDetails"
            element={<RentDealsDetails />}
          />

          <Route
            path="/Customer/MyAccount"
            element={<CustomerDashBoardMyAccount />}
          />
          <Route path="/Customer/Settings" element={<Customersettings />} />
          <Route
            path="/Customer/BookingCalender"
            element={<BookingCalender />}
          />
        </Route>

        {/* ------------------------ Garage-------------------------------------- */}
        <Route path="/Garage" element={<GarageDashBoard />}>
          <Route index element={<GarageHome />} />
          <Route
            path="/Garage/InsuranceOrderDetails"
            element={<GarageInsuranceOrderDetails />}
          />
          <Route
            path="/Garage/InsuranceOrder"
            element={<GarageInsuranceCalimList />}
          />
          <Route path="/Garage/GarageProfile" element={<GarageProfile />} />
        </Route>
        {/* ---------------------------Garage------------------------------------ */}
        {/* ------------------------ Garage-------------------------------------- */}
        <Route path="/GarageOnboard" element={<GarageOnboard />}>
          <Route index element={<LoginGarage />} />
          <Route path="/GarageOnboard/email" element={<GarageSignUpEmail />} />
          <Route path="/GarageOnboard/otpEmail" element={<GarageOtpScreen />} />
          <Route
            path="/GarageOnboard/viaphone"
            element={<GarageSignUpViaPhone />}
          />
          <Route
            path="/GarageOnboard/OtpViaPhone"
            element={<GarageOtpPageViaPhone />}
          />
          <Route
            path="/GarageOnboard/garagePassword"
            element={<GaragePassword />}
          />
          <Route
            path="/GarageOnboard/business"
            element={<GarageBusinessScreen />}
          />
          <Route
            path="/GarageOnboard/LicenseDetails"
            element={<GarageOnboardLicenseDetails />}
          />

          <Route
            path="/GarageOnboard/KYCDetails"
            element={<GarageOnboardKYCDetail />}
          />

          <Route
            path="/GarageOnboard/welcome"
            element={<GrageOnboardWelcome />}
          />
          <Route
            path="/GarageOnboard/ForgotPassWord"
            element={<GarageOnboardForgotPassWord />}
          />
          <Route
            path="/GarageOnboard/ForgotOtp"
            element={<GarageOnboardForgotOtp />}
          />
          <Route
            path="/GarageOnboard/NewPassword"
            element={<GarageOnboardNewPassword />}
          />
        </Route>
        {/* ---------------------------Garage------------------------------------ */}
      </Routes>
    </Router>
  );
};

export default RouterComponent;
