import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../Css/animation.css";
import { Navbar, Nav } from "react-bootstrap";
import {
  icon_black,
  icon_normal,
  home_backdrop,
  car_home,
  dashboard_special_deal,
  group_icon,
  mileage,
  petrol_pump,
  kirkos_logo_white_yellow,
  vector,
  noun_no_image,
} from "../../../assets/images";
import "./styles.css";
import DateTimeComponent from "../../../components/dateAndTime";
import moment from "moment";
import TimePicker from "../../../components/TimePicker/components/TimePicker";
import { StarFill } from "react-bootstrap-icons";
import FloatingInputLocation from "../../../components/FloatingInputLocation";
import { useForm } from "react-hook-form";
import axiosInstance from "../../../services/axios.instance";
import { ProductSearch, searchResult } from "../../../types";
import { useMutation } from "@tanstack/react-query";
import { API_URL } from "../../../services/apiConfig";
import { useNavigate } from "react-router-dom";
import CustomerCustomizeYourSearch from "../../../components/CustomerDashBoard/CustomerCustomizeYourSearch";
import QuickSearchModal from "../../../components/QuickSearchModal";
import { COLORS } from "../../../utils/colors";
import { initCapAllWords } from "../../../utils/stringUtil";

interface Location {
  latitude: number | null;
  longitude: number | null;
}

const HomePage = () => {
  const [position, setPosition] = useState(0);

  const handleAnimate = () => {
    // Update the state to trigger the animation
    setPosition(position + 1000); // You can adjust the value as needed
  };

  useEffect(() => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("refresh_token");
  }, []);
  //-------------------------------------------------------------------------------------------------------------------
  const [customizeResult, setCustomizeResult] = useState<any>(null);
  const [pickupLocation, setPickupLocation] = useState<Location>({
    latitude: 0,
    longitude: 0,
  });

  const [returnLocation, setReturnLocation] = useState<Location>({
    latitude: 0,
    longitude: 0,
  });

  const [pickupDate, setPickupDate] = useState<string>("");
  const [dropOffDate, setDropOffDate] = useState<string>("");
  const [searchCars, SetSearchCars] = useState<any>([]);

  const [selectedTime, setSelectedTime] = useState("");
  const [searchResultHeading, setSearchResultHeading] = useState(false);

  const [selectedDropTime, setSelectedDropTime] = useState("");

  const [searchValues, setSearchValues] = useState<any>({
    pickup: "",
    return: "",
  });

  const handleDropOffTimeChange = (newTime: any) => {
    setSelectedDropTime(newTime);
  };

  const handleTimeChange = (time: any) => {
    setSelectedTime(time);
  };

  const handleCustomizeResult = (customResult: any) => {
    setCustomizeResult(customResult);
  };

  const handleCustomization = () => {
    setShowCreateProduct(true);
  };

  const [pickupSuggestions, setPickupSuggestions] = useState<
    { name: string; latitude: number; longitude: number }[]
  >([]);
  const [returnSuggestions, setReturnSuggestions] = useState<
    { name: string; latitude: number; longitude: number }[]
  >([]);

  const fetchCoordinates = async (searchQuery: string) => {
    try {
      const response = await axiosInstance.get(
        `https://nominatim-carhire-dev.livelocal.delivery/search.php?q=${searchQuery}&format=geocodejson`
      );
      if (response.data && response.data.features.length > 0) {
        const suggestions = response.data.features.map((feature: any) => ({
          name: feature.properties.geocoding.label,
          latitude: feature.geometry.coordinates[1],
          longitude: feature.geometry.coordinates[0],
        }));
        return suggestions;
      }
      return [];
    } catch (error) {
      return [];
    }
  };

  const handlePickupLocationChange = async (value: string) => {
    setSearchValues((prevValues: any) => ({ ...prevValues, pickup: value }));
    try {
      const suggestions = await fetchCoordinates(value);
      if (suggestions.length > 0) {
        const { latitude, longitude } = suggestions[0];
        setPickupSuggestions(suggestions);
        setPickupLocation({ latitude, longitude });
      } else {
        setPickupSuggestions([]);
        setPickupLocation({ latitude: null, longitude: null });
      }
    } catch (error) {
      console.error("Error fetching pickup location suggestions:", error);
    }
  };

  const handleReturnLocationChange = async (value: string) => {
    setSearchValues((prevValues: any) => ({ ...prevValues, return: value }));
    try {
      const suggestions = await fetchCoordinates(value);
      if (suggestions.length > 0) {
        const { latitude, longitude } = suggestions[0];
        setReturnSuggestions(suggestions);
        setReturnLocation({ latitude, longitude });
      } else {
        setReturnSuggestions([]);
        setReturnLocation({ latitude: null, longitude: null });
      }
    } catch (error) {
      console.error("Error fetching return location suggestions:", error);
    }
  };

  const handlePickupLocationSelection = (selectedLocation: {
    name?: string;
    latitude: any;
    longitude: any;
  }) => {
    setSearchValues((prevValues: any) => ({
      ...prevValues,
      pickup: selectedLocation.name,
    }));

    setPickupSuggestions([]);
  };

  const handleReturnLocationSelection = (selectedLocation: {
    name?: string;
    latitude: any;
    longitude: any;
  }) => {
    setSearchValues((prevValues: any) => ({
      ...prevValues,
      return: selectedLocation.name,
    }));
    setReturnLocation({
      latitude: selectedLocation.latitude,
      longitude: selectedLocation.longitude,
    });
    setReturnSuggestions([]);
  };

  const handlePickupDateChange = (date: string) => {
    if (new Date(date) > new Date(dropOffDate)) {
      setDropOffDate(date);
    }
    setPickupDate(date);
  };

  const handleDropOffDateChange = (date: string) => {
    if (new Date(date) < new Date(pickupDate)) {
      setDropOffDate(pickupDate);
    } else {
      setDropOffDate(date);
    }
  };

  const { control } = useForm({
    mode: "onChange",
  });

  const handleSubmit = () => {
    let seatCapacity = null;
    let carType = null;
    let amountRange = 0;

    if (customizeResult) {
      const { seat_capacity, car_type, amount_range } = customizeResult;
      seatCapacity = seat_capacity;
      carType = car_type;
      amountRange = amount_range;
    }
    const convertToString = (value: number | null) =>
      value !== null ? value.toString() : "";

    const userData: ProductSearch = {
      index: 1,
      count: 10,
      sort_order: "ASC",
      pickup_location: {
        latitude: convertToString(pickupLocation.latitude),
        longitude: convertToString(pickupLocation.longitude),
      },
      return_location: {
        latitude: convertToString(returnLocation.latitude),
        longitude: convertToString(returnLocation.longitude),
      },
      pickup_date: moment(pickupDate).format("YYYY-MM-DDTkk:mm:ss.sss") + "Z",
      dropOff_date: moment(dropOffDate).format("YYYY-MM-DDTkk:mm:ss.sss") + "Z",

      pickup_time: selectedTime,
      dropOff_time: selectedDropTime,
      ...(seatCapacity && { seat_capacity: seatCapacity }),
      ...(carType && { car_type: carType }),
      ...(amountRange !== 0 && { amount_range: amountRange.toFixed(1) }),
    };

    handleSearch.mutate(userData);

    const searchResultSection = document.getElementById("searchResultSection");
    if (searchResultSection) {
      searchResultSection.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  let searchResultData: searchResult[] = [];

  const handleSearch = useMutation({
    mutationFn: (userData: ProductSearch) =>
      axiosInstance.post(API_URL.PRODUCT_SEARCH, userData),
    onSuccess: (successResponse) => {
      searchResultData = successResponse.data;
      SetSearchCars(searchResultData);
      setSearchResultHeading(true);
    },

    onError: (error) => {
      console.error("Search error:", error);
    },
  });

  const navigate = useNavigate();

  const [showCreateProduct, setShowCreateProduct] = useState(false);

  const [showAcceptConfirmationModal, setShowAcceptConfirmationModal] =
    useState(false);

  const [acceptConfirmationMessage, setAcceptConfirmationMessage] =
    useState("");

  const handleOpenConfirmationModal = (message: string) => {
    setAcceptConfirmationMessage(message);
    setShowAcceptConfirmationModal(true);
    // navigate("/signIn");
  };

  const handleBookingClick = () => {
    //navigate("/SignUp");
    handleOpenConfirmationModal("Login to continue on this website");
    // navigate("/signIn");
  };

  //------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <div className="div-home">
        {/* <div className="navbar navigation-bar mt-4"> */}
        <Navbar
          variant="dark"
          bg="transparent"
          expand="lg"
          className="custom-nav-font px-4 mx-4 pt-5"
        >
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Brand href="#home" className="navbar-brand-padding">
            <img
              src={kirkos_logo_white_yellow}
              alt="Kirkos-Logo"
              className="brands-logo"
            />
          </Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto align-items-center welcome_navbar ">
              <Nav.Link
                href="/merchant/signup"
                className="custom-nav-family new-name text-start"
              >
                BECOME A MERCHANT
              </Nav.Link>
              <Nav.Link
                href="/signUp"
                className="custom-nav-family new-name-two text-start"
              >
                JOIN AS A CUSTOMER
              </Nav.Link>
              <Nav.Link
                href="#make-car-active"
                className="custom-nav-family new-name-mid text-start"
              >
                MAKE YOUR IDEAL CAR ACTIVE AND EARN
              </Nav.Link>
              <Nav.Link
                href="/GarageOnboard"
                className="custom-nav-family  new-name-two text-start"
              >
                GARAGE
              </Nav.Link>
              <Nav.Link
                href="/signIn"
                className="custom-nav-family new-name-three"
              >
                CUSTOMER LOGIN{" "}
                <img src={icon_black} alt="Kirkos-Logo" className="icon_img" />
              </Nav.Link>
              <Nav.Link
                href="/SignInMerchant"
                className="custom-nav-family new-name-four"
              >
                MERCHANT LOGIN{" "}
                <img src={icon_normal} alt="Kirkos-Logo" className="icon_img" />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        {/* </div> */}
        <div className="container-fluid first-container px-2">
          <div className="first-container-background"></div>
          <div className="row text-box">
            <div className="col-12 text-center mb-4 ">
              <p className="responsive-text">
                RENT A CAR & MAKE YOUR <br /> TRIP ENJOYABLE
              </p>
            </div>
          </div>

          <div className="col-md-8  mx-auto px-4 bg-black rounded-3  white-box">
            {/* <div className="mb-3 d-flex align-items-center">
            <span className="custom-font-home mr-4 me-3">
              Rent a car with KIRKOS
            </span>
          </div> */}
            <div className="row mb-3 first-row">
              <div className="col-md-6  pt-2">
                <div className="first-box relative-div">
                  <FloatingInputLocation
                    inputType={"text"}
                    className={""}
                    placeholder={"Pick Up Location"}
                    label={"Pick Up Location"}
                    inputName="pickUpLocation "
                    selectedLocationName={searchValues.pickup}
                    // onInputChange={(value: string) =>{}}
                    onInputChange={(value: string) =>
                      handlePickupLocationChange(value)
                    }
                    control={control}
                    editable={true}
                  />
                  {pickupSuggestions.length > 0 && (
                    <div className="absolute-div">
                      <ul className="text-start" style={{ color: "white" }}>
                        {pickupSuggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() =>
                              handlePickupLocationSelection(suggestion)
                            }
                          >
                            {suggestion.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6 pt-2">
                <div className="first-box relative-div">
                  <FloatingInputLocation
                    inputType={"text"}
                    className={""}
                    placeholder={"Return Location"}
                    label={"Return Location"}
                    inputName="returnLocation"
                    onInputChange={(value: string) =>
                      handleReturnLocationChange(value)
                    }
                    selectedLocationName={searchValues.return}
                    control={control}
                    editable={true}
                  />
                  {returnSuggestions.length > 0 && (
                    <div className="absolute-div">
                      <ul className="text-start" style={{ color: "white" }}>
                        {returnSuggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() =>
                              handleReturnLocationSelection(suggestion)
                            }
                          >
                            {suggestion.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* <div className=" date-time-fields"> */}
            <div className="row align-items-end pb-2">
              <div className="col-md-8">
                <div className="row">
                  <div className="col-xl-3 col-md-6 pt-2">
                    <DateTimeComponent
                      pickerType="date"
                      placeholder="Pick Up Date"
                      label="Pick up Date"
                      value={pickupDate}
                      onChange={handlePickupDateChange}
                    />
                  </div>
                  <div className="col-xl-3 col-md-6 pt-2">
                    <DateTimeComponent
                      pickerType="date"
                      placeholder="Dropoff Date"
                      label="Dropoff Date"
                      value={dropOffDate}
                      onChange={handleDropOffDateChange}
                    />
                  </div>
                  <div className="col-xl-3 col-md-6 pt-2">
                    <TimePicker
                      label="Pick Up Time"
                      onChange={handleTimeChange}
                    />
                  </div>
                  <div className="col-xl-3 col-md-6 pt-2">
                    <TimePicker
                      label="Dropoff Time"
                      onChange={handleDropOffTimeChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="row">
                  <div className="col-md-12 pt-2 text-end">
                    <button
                      className=" btn all_button_style text-black mt-2"
                      onClick={handleSubmit}
                      disabled={
                        !pickupDate ||
                        !dropOffDate ||
                        !selectedTime ||
                        !selectedDropTime ||
                        !pickupLocation.latitude ||
                        !pickupLocation.longitude ||
                        !returnLocation.latitude ||
                        !returnLocation.longitude
                      }
                    >
                      SEARCH
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8 mx-auto text-end px-2">
            <button
              className="custom-search-my-account font-size-home-14"
              type="button"
              onClick={handleCustomization}
            >
              Customize your search
            </button>
          </div>
        </div>
        <div id="searchResultSection" />
        <div
          className={`search-list-home home-search-min-height align-items-center ${
            searchResultHeading ? "" : "d-none"
          }`}
        >
          {/* ---------   search div -------------- */}
          <div className="row search-list-home-width">
            <div className="col-md-12 myAccount-head text-light pt-3">
              <div
                className={`col-md-12 myAccount-head ${
                  searchResultHeading ? "" : "d-none"
                }`}
              >
                {searchCars.length === 0 ? (
                  <>
                    <div>
                      <img
                        src={vector}
                        alt="Error Logo"
                        className="img-fluid"
                      />
                    </div>
                    <span style={{ color: "#eeeeee" }}>
                      No Car On The Searched Location
                    </span>
                  </>
                ) : (
                  <span>Your Search Result Below :</span>
                )}
              </div>
            </div>
            {/* ---------------------  search mapping start ----------------------- */}
            {searchCars &&
              searchCars?.map((item: any, index: number) => (
                <div
                  key={index}
                  className="col-md-6  col-lg-4 animate-by-scroll"
                >
                  <div className="row normal-height-search-list">
                    <div className="col-md-12 mx-auto">
                      <div className="card  scale-down">
                        {/* <img src={dashboard_mini_cooper_img} alt="" /> */}
                        {item?.product_details?.images?.length > 0 ? (
                          <img
                            className="img-fluid search-result-image"
                            src={item.product_details.images[0]}
                            alt=""
                          />
                        ) : (
                          <img
                            src={noun_no_image}
                            alt=""
                            className="img-fluid search-result-image"
                          />
                        )}
                        <div className="card-body">
                          <div className="row text-start">
                            <div className="col-6">
                              <div className="car-cat-search ">
                                {initCapAllWords(item.product_name)}
                              </div>
                              <div className="car-name-search">
                                {initCapAllWords(item.product_name)}
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="text-center merchant-name-div mx-2">
                                <span>
                                  {" "}
                                  {item?.site_logo ? (
                                    <img
                                      className="img-fluid logo-merchant"
                                      src={item?.site_logo}
                                      alt=""
                                    />
                                  ) : (
                                    <span>
                                      {String(item?.shop_name)?.toUpperCase()}
                                    </span>
                                  )}
                                </span>
                              </div>
                              <div className="text-center pt-2">
                                <StarFill color={COLORS.primary} size={12} />
                                <span className="ps-2">
                                  <StarFill color={COLORS.primary} size={12} />
                                </span>
                                <span className="ps-2">
                                  <StarFill color={COLORS.primary} size={12} />
                                </span>
                                <span className="ps-2">
                                  <StarFill color={COLORS.primary} size={12} />
                                </span>
                                <span className="ps-2">
                                  <StarFill color={COLORS.primary} size={12} />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row pt-2 search-features align-items-center">
                            <div className="col-6">
                              <div className="col-md-12 text-start  pb-2 dashboard-poppins-font-style  car-name-search-bold">
                                Features
                              </div>
                              <div className="d-flex">
                                <div className="text-start dashboard-poppins-font-style me-2">
                                  <img
                                    src={group_icon}
                                    alt=""
                                    className="img-fluid img-width-icon"
                                  />
                                  &nbsp;
                                  {item.product_details_car.number_of_seats}
                                </div>
                                <div className="text-start dashboard-poppins-font-style">
                                  <img
                                    src={petrol_pump}
                                    alt=""
                                    className="img-fluid img-width-icon"
                                  />
                                  &nbsp;{item.product_details_car.fuel_type}
                                </div>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="search-car-special-deal text-center">
                                <img
                                  src={dashboard_special_deal}
                                  alt=""
                                  width={13}
                                  className=""
                                />
                                <span className="dashboard-poppins-font-style">
                                  {" "}
                                  Special Deal{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row search-features">
                            <div className="col-md-12 text-start pt-1 ">
                              <img
                                src={mileage}
                                alt="file_browse_icon"
                                className="img-fluid img-width-icon"
                                style={{ alignItems: "left" }}
                              />
                              <span className="px-1 dashboard-poppins-font-style">
                                Unlimited Mileage
                              </span>
                            </div>
                          </div>
                          <div className="row text-start pt-2">
                            <div className="col-4">
                              <div className="font-size-home-14 dashboard-poppins-font-style">
                                AED{" "}
                                <span>
                                  {item.product_details_car.price_per_day}
                                </span>
                              </div>
                              <div className=" car-name-search-bold dashboard-poppins-font-style">
                                Per Day
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="font-size-home-14 dashboard-poppins-font-style">
                                AED{" "}
                                <span>
                                  {item.product_details_car.price_per_week}
                                </span>
                              </div>
                              <div className="car-name-search dashboard-poppins-font-style">
                                Per Week
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="font-size-home-14 dashboard-poppins-font-style">
                                AED{" "}
                                <span>
                                  {item.product_details_car.price_per_month}
                                </span>
                              </div>
                              <div className="car-name-search dashboard-poppins-font-style">
                                Per Month
                              </div>
                            </div>
                          </div>
                          <div className="row pt-2">
                            <div className="col-md-12 text-end">
                              <button
                                className="book-now-button-style "
                                // onClick={() =>
                                //   handleBookingClick(item.product_id)
                                // }
                                onClick={() => handleBookingClick()}
                              >
                                BOOK NOW
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            {/* ---------------------  search mapping end ----------------------- */}
          </div>
          {/* ---------   search div -------------- */}
        </div>

        <div
          className={`image-container-custom ${
            searchResultHeading ? "d-none" : ""
          }`}
        >
          <img
            src={car_home}
            alt="car_image"
            className="animated-image"
            style={{ transform: `translateX(${position}px)` }}
          />
        </div>
        {/* <Button variant="primary" onClick={handleAnimate} className=" search-button mt-2">
            Animate
          </Button> */}
        <div className="container-fluid fixed-bottom">
          <img
            src={home_backdrop}
            alt="Kirkos-Logo"
            className="brand-logo img-fluid"
          />
        </div>
      </div>
      <CustomerCustomizeYourSearch
        isOpen={showCreateProduct} // Pass the state as isOpen
        onClose={() => setShowCreateProduct(false)} // Close action handler
        onCustomizeResult={handleCustomizeResult}
      />
      <QuickSearchModal
        isOpen={showAcceptConfirmationModal}
        onClose={() => setShowAcceptConfirmationModal(false)}
        onConfirm={() => {
          setShowAcceptConfirmationModal(false);
          navigate("/signIn");
        }}
        message={acceptConfirmationMessage}
      />
    </>
  );
};

export default HomePage;
